import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Context } from '../contexts/Store';
import {
	priceFormatter,
	dynamicSort,
	getStatus,
	stringToSlug,
	findFloorplans,
} from '../helpers/utilities';
import { TotalArea, enableButton } from '../components/TotalArea';

import Meta from './Meta';
import AvailabilityBadge from './AvailabilityBadge';
import FavButton from '../components/FavButton';
import InteriorTourButton from '../components/InteriorTourButton';
import FloorplanGalleryButton from '../components/FloorplanGalleryButton';
import ArrowIcon from './ArrowIcon';
import ActionButton from './ActionButton';
import ToggleButton from './ToggleButton';
import ImageSlider from './ImageSlider';

import '../assets/styles/components/PlotCardPreview.scss';
import PlotSpecificationButton from './PlotSpecificationButton';
import PlotIncentivesButton from './PlotIncentivesButton';

function PlotCardPreview({ item, close }) {
	const { state } = useContext(Context);
	const location = useLocation();

	let status = getStatus(state.plotStatuses, 'id', item.plotStatusId);

	let images = item.plotType.assets.slice().sort(dynamicSort('order'));

	let phase = null;
	if (item.customFields && item.customFields.length > 0) {
		phase = item.customFields.find(({ displayName }) => stringToSlug(displayName) === `phase`);
	}

	let interiorTour = null;
	const typeSlug = stringToSlug(item.plotType.name);
	if (state.menuItems.interiorTours) {
		interiorTour = state.menuItems.interiorTours.find((el) => el.slug === typeSlug);
	}

	let exteriorTourView = null;
	if (state.plotVRs) {
		exteriorTourView = state.plotVRs.find(
			({ plot }) => stringToSlug(plot) === `plot-${item.plotNumber}`
		);
	}

	let specificationLink = null;
	if (item.plotType.specificationLink) {
		specificationLink = item.plotType.specificationLink;
	}

	let incentivesLink = null;
	if (state.menuItems.incentives) {
		incentivesLink = state.menuItems.incentives;
	}

	let floorplans = findFloorplans(item.plotType.assets);
	// If we have floorplans, remove the duplicates from the images array
	if (floorplans) {
		images = images.filter((image) => image.name.indexOf('FP_') === -1);
	}

	if (item.plotSpecificAssets && item.plotSpecificAssets.length > 0) {
		floorplans = findFloorplans(item.plotSpecificAssets);
	}

	const sliderImages = location.pathname.includes('exterior-tour')
		? floorplans
		: [...images, ...floorplans];

	return (
		<section className='PlotCardPreview'>
			<div className='PlotCardPreview__ImageHeader'>
				<ImageSlider images={sliderImages} imagesCover={false} autoHeight={true} />

				<div className='PlotCardPreview__Floorplans'>
					<FloorplanGalleryButton
						id={item.id}
						plotType={item.plotType}
						floorplans={floorplans}
						display='icon'
					/>
				</div>
			</div>

			<div className='PlotCardPreview__Main'>
				<div className='PlotCardPreview__Header'>
					<div className='PlotCardPreview__Intro'>
						<h2 className='PlotCardPreview__Number'>
							<Link to={`/homes/${item.id}`}>Plot {item.plotNumber}</Link>
						</h2>
						<h3 className='PlotCardPreview__Name'>{item.name}</h3>
					</div>
					<div className='PlotCardPreview__Availability'>
						<AvailabilityBadge status={status} />

						<p className='PlotCardPreview__Price'>
							{status.name === 'Available' ? priceFormatter(item.price) : null}
							{status.name === 'Unreleased' ? '£POA' : null}
						</p>
					</div>
				</div>

				<div className='PlotCardPreview__Meta'>
					<Meta
						beds={item.plotType.numberOfBeds}
						area={TotalArea(item.plotType)}
						display='row'
						enableButton={enableButton(item.plotType)}
					/>
				</div>

				<div className='PlotCardPreview__Extras'>
					<InteriorTourButton tour={interiorTour} />
					{exteriorTourView && !location.pathname.includes('exterior-tour') && (
						<ActionButton
							icon='streetview'
							to={`/exterior-tour/${phase ? stringToSlug(phase.displayName) : 'current'}/${
								exteriorTourView.arg
							}`}
						>
							View Street
						</ActionButton>
					)}
					<PlotSpecificationButton link={specificationLink} />
					<PlotIncentivesButton link={incentivesLink} />
				</div>

				<div className='PlotCardPreview__Actions'>
					<ToggleButton variant='secondary' size='sm' icon='close' onClick={close} />
					<FavButton plotID={item.id} />
					<Link className='HomeLink' to={`/homes/${item.id}`}>
						View Home <ArrowIcon />
					</Link>
				</div>
			</div>
		</section>
	);
}

export default PlotCardPreview;
