import { useContext, useState, useEffect } from 'react';
import { Context } from '../contexts/Store';

export function useApartmentStatus(id) {
	let { state } = useContext(Context);
	const [plotsArray, setPlotsArray] = useState([]);
	const [statusArray, setStatusArray] = useState([]);
	const [colour, setColour] = useState();
	const [markerVisible, setMarkerVisible] = useState(true);
	const [hasFavourite, setHasFavourite] = useState(false);

	useEffect(() => {
		getPlotsForContainer(state.plotContainers, id);
	}, [id, state.filteredHomes, state.favPlots]);

	function getPlotsForContainer(obj, targetId) {
		setPlotsArray([]);
		obj.forEach((plotContainerData) => {
			if (plotContainerData.id === targetId) {
				for (const thisArrayItem of plotContainerData.interactiveRegions) {
					const floorPlots = plotContainerData.plotContainers.find((item) => {
						return item.id == thisArrayItem.targetId;
					});
					setPlotsArray((obj) => [...obj, ...floorPlots.plotIds]);
				}
				return false;
			}
			if (Object.prototype.hasOwnProperty.call(plotContainerData, 'plotContainers')) {
				getPlotsForContainer(plotContainerData.plotContainers, targetId);
			}
			return true;
		});
	}

	const checkForFavPlot = () => {
		if (!state.favPlots.length) return;
		plotsArray.every((arrayItem) => {
			const isFavPlot = state.favPlots.includes(arrayItem);
			if (isFavPlot) {
				setHasFavourite(true);
				return false;
			}
			return true;
		});
	};

	useEffect(() => {
		setStatusArray([]);
		const _plotArray = [];
		state.plotContainers.forEach((plotContainerData) => {
			if (plotContainerData) {
				for (const _plotContainer of plotContainerData.plotContainers) {
					if (_plotContainer.id === id) {
						for (const thisArrayItem of _plotContainer.interactiveRegions) {
							const floorPlots = _plotContainer.plotContainers.find((item) => {
								return item.id == thisArrayItem.targetId;
							});
							for (const _plot of floorPlots.plotIds) {
								_plotArray.push(_plot);
							}
						}
					}
				}
			}
		});

		for (let a = 0; a < _plotArray.length; a++) {
			const thisPlotInfo = state.filteredHomes.find((plotItem) => plotItem.id == _plotArray[a]);

			if (thisPlotInfo) {
				const thisPlotStatus = state.plotStatuses.find(
					(statusItem) => statusItem.id == thisPlotInfo.plotStatusId
				);

				setStatusArray((obj) => [...obj, thisPlotStatus]);
			}
		}
		checkForFavPlot();
	}, [plotsArray]);

	useEffect(() => {
		if (!statusArray.length) {
			setMarkerVisible(false);
			return;
		}
		setMarkerVisible(true);
		let result = 'Sold';
		if (
			statusArray.find((item) => {
				return item.name === 'Reserved';
			})
		)
			result = 'Reserved';

		if (
			statusArray.find((item) => {
				return item.name === 'Unreleased';
			})
		)
			result = 'Unreleased';

		if (
			statusArray.find((item) => {
				return item.name === 'Available';
			})
		)
			result = 'Available';

		const b = state.plotStatuses.find((statusItem) => statusItem.name == result);
		setColour(b.color);
	}, [statusArray]);

	return { colour: colour, markerVisible: markerVisible, hasFavourite: hasFavourite };
}
