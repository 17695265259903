import { Routes, Route } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
import Site from '../pages/Site';

import Home from './Home';
import Homes from './Homes';
import Plot from './Plot';
import Gallery from './Gallery';
import Favourites from './Favourites';
import Map from './Map';
import ExteriorTour from './ExteriorTour';
import AppSettings from './AppSettings';
import Brochures from './Brochures';
import SyncFavourites from './SyncFavourites';
import Compare from './Compare';
import HybridPlus from './HybridPlus';
import InteriorTours from './InteriorTours';

function AppRoutes() {
	return (
		<>
			<ScrollToTop />
			<Routes>
				<Route index element={<Home />} />
				<Route path='homes' element={<Homes />} />
				<Route path='/homes/:id' element={<Plot />} />
				<Route path='/site' element={<Site />}>
					<Route path='/site/:id' element={<Site />} />
				</Route>
				<Route path='gallery' element={<Gallery />} />
				<Route path='local-area' element={<Map />} />
				<Route path='/favourites' element={<Favourites />} />
				<Route path='/favourites/sync' element={<SyncFavourites />} />
				<Route path='/exterior-tour' element={<ExteriorTour />}>
					<Route path=':phase' element={<ExteriorTour />}>
						<Route path=':id' element={<ExteriorTour />} />
					</Route>
				</Route>
				<Route path='/app-settings/:key' element={<AppSettings />} />
				<Route path='/brochures' element={<Brochures />} />
				<Route path='/compare' element={<Compare />} />
				<Route path='/brochure' element={<HybridPlus />} />
				<Route path='/interior-tours' element={<InteriorTours />} />
			</Routes>
		</>
	);
}

export default AppRoutes;
