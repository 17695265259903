const initialState = {
	appLoaded: false,
	devId: window['runConfig'].devId,
	devKey: window['runConfig'].devKEY,

	loaded: [],
	error: [],
	allLoaded: false,

	devSpinPath: window['runConfig'].devSpinPath ? window['runConfig'].devSpinPath : '',
	devName: '',
	clientName: '',
	devStyle: {},
	developmentType: window['runConfig'].developmentType,

	mapStyle: window['runConfig'].mapStyle,

	plots: [],
	plotStatuses: [],
	types: [],
	activeType: '',

	filterRoutes: ['/homes', '/compare', '/site'],
	filters: [],
	excludedFilters: window['runConfig'].excludedFilters,
	availableHomes: [],
	filteredHomes: [],
	homesSortKey: 'plotNumber',
	homesSortOrder: 'asc',
	rangeLabels: [],
	isFiltersOpen: false,
	selectedPhase: 0,
	selectedPhaseName: '',

	menuItemsRaw: [],
	menuItems: [],
	plotVRs: false,
	plotContainers: false,
	developmentPhases: false,
	isPreviewPanelOpen: false,
	selectedPlotMarkerId: false,
	selectedPlotId: false,

	favPlots: localStorage.getItem('favPlots') ? JSON.parse(localStorage.getItem('favPlots')) : false,
	favPlotsToRemove: [],
	favPlotsToAdd: [],
	favRemoteUpdate: 0,
	showLogin: window['runConfig'].showLogin,
	loggedIn: localStorage.getItem('user') ? Boolean(localStorage.getItem('user')) : false,
	user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},

	spinMarkerData: [],
	spinConfigData: [],
	globalSpinConfigData: false,
	currentLight: { ID: null, status: 'off' },

	globalDisclaimer: window['runConfig'].globalDisclaimer,
	indexPage: window['runConfig'].indexPage,

	environment: window['runConfig'].environment,

	spinAssetsForDevice: false,
	isMobileDevice: false,
	isTabletDevice: false,
	supportsWebp: false,
	siteplanToggle: true,

	isCompareMode: false,
	comparePlots: [],
	compareShowDims: false,
	homesDefaultView: window['runConfig'].homesDefaultView
		? window['runConfig'].homesDefaultView
		: 'types',
	hideHeader: false,
	metricSystem: window['runConfig'].metricSystem ? window['runConfig'].metricSystem : false,
};

export { initialState as initialState };
