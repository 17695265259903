import { stringToSlug } from './utilities';

export default function parseMenuItems(data) {
	let items = {};

	function getMenuItem(data, name, nodeType) {
		let url = false;
		let item = data.find((el) => el.name === name && el.nodeType === nodeType);
		if (item && nodeType === 'Asset') {
			if (item.assets && item.assets[0]) {
				url = item.assets[0].fileUrl;
			}
		} else if (item && nodeType === 'Web Asset') {
			url = item.webAssetUri;
		}
		return url;
	}

	function getMenuSubItems(data, name, nodeType) {
		let items = [];
		let group = data.find((el) => el.name === name && el.nodeType === 'Menu Item Container');
		if (group && group.subNodes) {
			for (let i = 0; i < group.subNodes.length; ++i) {
				let name = group.subNodes[i].name;
				let slug = stringToSlug(name);

				let url = false;
				let fileSize = '';
				if (nodeType === 'Asset') {
					if (group.subNodes[i] && group.subNodes[i].assets[0]) {
						url = group.subNodes[i].assets[0].fileUrl;
						fileSize = group.subNodes[i].assets[0].fileSize;
					}
				} else if (nodeType === 'Web Asset') {
					url = group.subNodes[i].webAssetUri;
				}

				items.push({ slug: slug, url: url, title: name, fileSize });
			}
		} else {
			return null;
		}
		return items;
	}

	items.plotVRs = getMenuItem(data, 'Plots2VRs', 'Web Asset');

	items.interiorTours = getMenuSubItems(data, 'Interior VR Tours', 'Web Asset');

	items.exteriorTours = getMenuSubItems(data, 'Exterior VR Tour', 'Web Asset');

	items.siteplans = getMenuSubItems(data, '3DSiteplans', 'Web Asset');

	items.brochures = getMenuSubItems(data, 'PDFs', 'Asset');

	items.masterPlans = getMenuSubItems(data, 'Masterplans', 'Asset');

	items.digitalBrochure = getMenuItem(data, 'Digital Brochure', 'Web Asset');

	items.incentives = getMenuItem(data, 'Incentives', 'Web Asset');

	items.interiorGallery = getMenuItem(data, 'Interior Tours', 'Asset');

	return items;
}
