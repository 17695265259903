import React, { useState, useContext, useRef, useEffect } from 'react';
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion';
import { useLocation, NavLink, Link } from 'react-router-dom';

import { Context } from '../contexts/Store';

import '../assets/styles/components/Menu.scss';
import useDropdown from '../hooks/useDropdown';

const PATH_HOMES = '/homes';

function Menu() {
	const { state, dispatch } = useContext(Context);
	const [selectedItem, setSelectedItem] = useState();
	const location = useLocation();

	// menu
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const buttonRef = useRef(null);
	const menuContainer = useRef(null);
	useDropdown(buttonRef, menuContainer, setIsMenuOpen);

	const logo = state.devStyle.images.additionalLogoUrl;

	const items = [
		{
			label: 'Homes',
			path: '/homes',
		},
		{
			label: 'Siteplan',
			path: '/site',
		},
		{
			label: 'Local Area',
			path: '/local-area',
		},
		{
			label: 'Gallery',
			path: '/gallery',
		},
	];

	if (state.menuItems.digitalBrochure) {
		items.push({
			label: 'Brochure',
			path: '/brochure',
		});
	}

	if (!state.menuItems.digitalBrochure && state.menuItems.brochures) {
		items.push({
			label: 'Brochures',
			path: '/brochures',
		});
	}

	if (state.menuItems.interiorGallery) {
		items.push({
			label: 'Interior Tours',
			path: '/interior-tours',
		});
	}

	if (state.menuItems.exteriorTours && state.menuItems.exteriorTours.length > 0) {
		items.push({
			label: 'Exterior Tour',
			path: '/exterior-tour',
		});
	}

	if (state.indexPage) {
		items.unshift({
			label: state.indexPage.label,
			path: state.indexPage.url,
		});
	}

	useEffect(() => {
		let thisPath = location.pathname;

		if (thisPath.includes('siteplanspin')) thisPath = '/site';

		let activeItem = items.find((item) => thisPath.includes(item.path)) || false;
		setSelectedItem(activeItem);
	}, [location.pathname]);

	const onItemHandler = (item) => {
		if (state.isCompareMode && state.comparePlots.length <= 1 && item.path !== PATH_HOMES) {
			dispatch({
				type: 'setIsCompareMode',
				data: false,
			});
		}

		setSelectedItem(item);
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<AnimateSharedLayout>
			<div className='Menu'>
				<button
					className={`Menu__Toggle ${isMenuOpen ? 'active' : ''}`}
					onClick={() => {
						setIsMenuOpen((prev) => !prev);
					}}
					aria-controls='#menu'
					ref={buttonRef}
				>
					<span className='Menu__Burger'>
						<span className='Menu__Burger__line Menu__Burger__line--1'></span>
						<span className='Menu__Burger__line Menu__Burger__line--2'></span>
						<span className='Menu__Burger__line Menu__Burger__line--3'></span>
					</span>
					Menu
				</button>
				<AnimatePresence>
					{isMenuOpen && (
						<motion.div
							className='Menu__Container'
							style={{ originX: 0.95, originY: 0.95 }}
							initial={{ opacity: 0, y: 10, scale: 0.5 }}
							animate={{ opacity: 1, y: -5, scale: 1 }}
							exit={{ opacity: 0, y: 10, scale: 0.5 }}
							transition={{
								duration: 0.25,
								ease: [0.6, 0.01, -0.05, 0.95],
							}}
							ref={menuContainer}
						>
							{logo && state.isMobileDevice && (
								<>
									<Link to='/'>
										<img src={logo} alt='' className='Menu__Logo' loading='lazy' />
									</Link>
									<p className='Menu__Development'>{state.devName.name}</p>
								</>
							)}
							<motion.ul id='menu' className='Menu__Items'>
								{items.map((item, i) => {
									const isSelected = selectedItem.path === item.path;

									return (
										<li key={i} className='Menu__Item' onClick={() => onItemHandler(item)}>
											{isSelected && (
												<motion.div
													layoutId='Menu__Bubble'
													className='Menu__Bubble'
													initial={false}
													animate={{ backgroundColor: 'var(--menu-primary-color) ' }}
												/>
											)}
											{item.path === state.indexPage?.url ? (
												<a href={item.path}>{item.label}</a>
											) : item.path.includes('http') ? (
												<a href={item.path} target='_blank' rel='noreferrer'>
													{item.label}
												</a>
											) : (
												<NavLink to={item.path}>{item.label}</NavLink>
											)}
										</li>
									);
								})}
							</motion.ul>
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</AnimateSharedLayout>
	);
}

export default Menu;
