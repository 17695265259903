import { React, useState, useContext } from 'react';
import { Context } from '../contexts/Store';
import { Helmet } from 'react-helmet';

import Button from '../components/Button';
import IconButton from '../components/IconButton';
import HomeFilters from '../components/HomeFilters';
import BackButton from '../components/BackButton';

import '../assets/styles/pages/Home.scss';

function Home() {
	const [activeView, setActiveView] = useState('splash');
	const { state, dispatch } = useContext(Context);
	const { plotContainers } = state;

	const updatePhaseId = (_phaseId) => {
		dispatch({
			type: 'setSelectedPhase',
			data: _phaseId,
		});

		dispatch({
			type: 'setSelectedPhaseName',
			data: plotContainers
				.filter((e) => e.id === Number(_phaseId))[0]
				.name.replace(' ', '-')
				.toLowerCase(),
		});
	};

	return (
		<div className='Home'>
			<Helmet>
				<title>{`${state.devName.name} - Places for People`}</title>
			</Helmet>

			{state.devStyle.images.wallIdleVideoUrl && !state.isMobileDevice && (
				<div className='Home__Media Home__Media--video'>
					<video
						className='Home__Video'
						playsInline='playsinline'
						autoPlay='autoplay'
						muted='muted'
						loop='loop'
					>
						<source src={state.devStyle.images.wallIdleVideoUrl} type='video/mp4' />
					</video>
				</div>
			)}

			{state.devStyle.images.backgroundImageUrl &&
				(!state.devStyle.images.wallIdleVideoUrl || state.isMobileDevice) && (
					<div className='Home__Media Home__Media--image'>
						<img src={state.devStyle.images.backgroundImageUrl} alt='' />
					</div>
				)}

			{activeView === 'splash' && (
				<div className='Home__Content'>
					<img src={state.devStyle.images.wallLogoUrl} alt='' className='Home__DevLogo' />
					<Button variant='primary' onClick={() => setActiveView('buttons')}>
						Take a Look
					</Button>
				</div>
			)}

			{activeView === 'buttons' && (
				<div className='Home__ButtonGroup'>
					<div className='Home__ButtonGroup__Buttons'>
						{state.plotContainers && state.plotContainers.length > 1 ? (
							state.plotContainers.map((e) =>
								e.plotContainerType.name === 'Site' ? (
									<IconButton
										key={e.id}
										to={`site/${e.id}`}
										onClick={() => updatePhaseId(e.id)}
										icon='search'
									>
										Siteplan - {e.name}
									</IconButton>
								) : null
							)
						) : (
							<IconButton to='site' icon='search'>
								Siteplan
							</IconButton>
						)}
						{state.menuItems.exteriorTours && state.menuItems.exteriorTours.length > 0 && (
							<IconButton to='exterior-tour' icon='street-view' href='exterior-tour'>
								View Street
							</IconButton>
						)}
						<IconButton icon='filters' onClick={() => setActiveView('filters')}>
							Filter Homes
						</IconButton>
						{state.menuItems.digitalBrochure && (
							<IconButton to='brochure' icon='brochure'>
								View Lifestyle
							</IconButton>
						)}
					</div>
					<BackButton onClick={() => setActiveView('splash')} />
				</div>
			)}

			{activeView === 'filters' && (
				<div className='Home__Filters'>
					<HomeFilters />
					<BackButton onClick={() => setActiveView('buttons')} />
				</div>
			)}
		</div>
	);
}

export default Home;
